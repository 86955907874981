<script lang="ts">
  import { Modal } from "flowbite-svelte";
  import Icon from "@elements/Icon.svelte";
  import Button from "@elements/Button.svelte";
  import { openSearchModal, isSearchModalOpen } from "../../stores/modalStore";

  let searchInput: HTMLInputElement;

  $: if ($isSearchModalOpen) {
    setTimeout(() => searchInput?.focus(), 0);
  }
</script>

<button
  on:click={openSearchModal}
  class="font-light tracking-wider stroke-white fill-white inline-flex items-center gap-2 border-white border rounded-full px-5 py-2 backdrop-blur-sm bg-white/30 text-white lg:w-64 text-left"
>
  <Icon name="search" size="17px" /> Search
</button>

<Modal
  bind:open={$isSearchModalOpen}
  class="w-full "
  size="md"
  autoclose={false}
  outsideclose
  classBody="text-white p-6 rounded-lgs"
  classBackdrop="transition-all duration-300 backdrop-blur-lg bg-black/30"
  placement="top-center"
>
  <h3 slot="header">Search</h3>
  <form action="/site-search" method="get" class="mb-4">
    <div class="relative">
      <div
        class="absolute top-[10px] flex items-center pl-3 pointer-events-none"
      >
        <Icon name="search" size={25} class="text-n50 fill-n50" />
      </div>
      <input
        bind:this={searchInput}
        type="search"
        name="s"
        placeholder="Search Wild at Heart..."
        required
        class="rounded-md w-full pl-10 border-n200 placeholder-n50 text-n800 focus:ring-s500 focus:border-s500 mb-6"
      />
      <Button
        type="submit"
        text="Search"
        variant="secondary"
        class="float-right bg-p300"
      />
    </div>
  </form>
</Modal>
